import Guid from 'common/Guid';
import Menu from 'common/Menu';
import FormBlock from 'blocks/FormBlock';
import Carousel from 'blocks/Carousel';
import CarouselVideos from 'blocks/Carousel-videos';
import Tabs from 'blocks/Tabs';
import Accordions from 'blocks/Accordions';
import Video from 'blocks/Video';
import TweetTimelineStyled from 'blocks/Tweet-timeline-styled';
import Autosize from 'blocks/Autosize-textarea';
import Youtube from 'blocks/Youtube';

// import '../../vendors/tarteaucitronjs/tarteaucitron';

(() => {
	if ('production' !== process.env.NODE_ENV) {
		const guid = new Guid();

		guid.init();
	}
	const menu = new Menu('.burger');
	menu.init();

	//
	const form = new FormBlock('.swpm-item input');
	form.init();

	const contactForm = new FormBlock('.Form__input');
	contactForm.init();

	//
	const accordions = new Accordions('.accordion-container');
	accordions.init();

	//
	const video = new Video('.video-js');
	video.init();

	//
	const tabs = new Tabs('.tabs');
	tabs.init();

	//
	const tweetTimelineStyled = new TweetTimelineStyled('.twitter-timeline');
	tweetTimelineStyled.init();

	//
	const autosize = new Autosize('textarea');
	autosize.init();

	//
	const youtube = new Youtube('youtube');
	youtube.init();

	//
	const carousels = [...document.querySelectorAll('.js-carousel')];
	const carouselsVideos = [...document.querySelectorAll('.swiper-container')];
	carousels.map(item => new Carousel(item).init());
	carouselsVideos.map(item => new CarouselVideos(item).init());
})();
