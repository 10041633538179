import autosize from 'autosize';

export default class FormBlock {
	constructor(element) {
		this.$textarea = document.querySelectorAll(element);
	}

	init() {
		if (null === this.$textarea) {
			return false;
		}

		console.log('arrraa');
		autosize(this.$textarea);

		return true;
	}
}
