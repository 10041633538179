import Swiper, { Navigation, Pagination } from 'swiper';

/**
 * Carousel
 *
 * @param obj element DOM object.
 */
export default class Carousel {
	constructor(element) {
		this.rootElement = element;
	}

	init() {
		if (null === this.rootElement) {
			return false;
		}

		console.log('YT slider on the place ----+++++');
		Swiper.use([Navigation, Pagination]);

		// 2. This code loads the IFrame Player API code asynchronously.
		const tag = document.createElement('script');

		tag.src = 'https://www.youtube.com/iframe_api';
		const firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		function createCustomThumbail(url) {
			console.log('createCustomThumbail --->');
			return `<img class="youtube-thumbnail" src="${url}" alt="Youtube Preview" /><div class="youtube-play-btn"></div>`;
		}

		// function createIframe(v, id) {
		// 	const players = [];

		// 	const iframe = document.createElement('iframe');
		// 	console.log(v);
		// 	iframe.setAttribute(
		// 		'src',
		// 		`//www.youtube.com/embed/${id}?autoplay=1&color=white&autohide=2&modestbranding=1&border=0&wmode=opaque&enablejsapi=1&showinfo=0&rel=0`,
		// 	);
		// 	iframe.setAttribute('frameborder', '0');
		// 	iframe.setAttribute('class', 'youtube-iframe');
		// 	v.firstChild.replaceWith(iframe);
		// }

		window.onYouTubeIframeAPIReady = () => {
			// Check all slides and initialize video players
			const swiper = document.getElementById('video-swiper');
			const slides = swiper.getElementsByClassName('swiper-slide');

			const players = [];

			for (let i = 0; i < slides.length; i += 1) {
				const element = slides[i].getElementsByClassName('yt-player')[0];
				const cover = slides[i].getElementsByClassName('yt-cover')[0];
				console.log('element --->', element.parentNode);
				const id = element.getAttribute('data-id');
				const placeholder = element.hasAttribute('data-thumbnail')
					? element.getAttribute('data-thumbnail')
					: '';
				console.log('yt-cover', cover);
				if (placeholder.length) cover.innerHTML = createCustomThumbail(placeholder);
				else {
					const parent = slides[i].getElementsByClassName('video-container')[0];
					const player = new YT.Player(element, {
						height: '315',
						width: '560',
						videoId: id,
						playerVars: {
							autoplay: false,
							color: '#7fbc03',
							modestbranding: true,
							rel: 0,
						},
					});
					parent.classList.remove('pristine');
					players.push(player);
				}

				slides[i].addEventListener('click', () => {
					console.log('----->  this', this);
					// parent.remove('pristine');
					const parent2 = slides[i].getElementsByClassName('video-container')[0];
					const element2 = slides[i].getElementsByClassName('yt-player')[0];

					parent2.classList.remove('pristine');

					const player = new YT.Player(element2, {
						height: '315',
						width: '560',
						videoId: id,
						playerVars: {
							autoplay: true,
							color: '#7fbc03',
							modestbranding: true,
							rel: 0,
						},
					});
					players.push(player);
				});
			}

			const mySwiper = new Swiper('.swiper-container', {
				effect: 'slide',
				spaceBetween: 20,
				slidesPerView: 1,
				centeredSlides: true,
				navigation: {
					nextEl: '.js-carousel-2-button-next',
					prevEl: '.js-carousel-2-button-prev',
				},
				breakpoints: {
					750: {
						spaceBetween: 0,
					},
				},
				on: {
					slideChange: () => {
						players[mySwiper.previousIndex].pauseVideo();
					},
				},
			});
		};

		return true;
	}
}
