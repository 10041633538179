import 'video.js/dist/video.es';
import 'videojs-youtube/dist/Youtube';

export default class Video {
	constructor(element) {
		this.$video = document.querySelector(element);
	}

	init() {
		if (null === this.$video) {
			return false;
		}

		return true;
	}
}
