/**
 * Carousel
 *
 * @param obj element DOM object.
 */
export default class Carousel {
	constructor(element) {
		this.rootElement = element;
	}

	init() {
		if (null === this.rootElement) {
			return false;
		}

		console.log('YT slider on the place ----+++++');

		// 2. This code loads the IFrame Player API code asynchronously.
		const tag = document.createElement('script');

		tag.src = 'https://www.youtube.com/iframe_api';
		const firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		function createCustomThumbail(url) {
			console.log('createCustomThumbail --->');
			return `<img class="youtube-thumbnail" src="${url}" alt="Youtube Preview" /><div class="youtube-play-btn"></div>`;
		}

		window.onYouTubeIframeAPIReady = () => {
			// Check all slides and initialize video players
			const slides = document.getElementsByClassName('youtube-item');

			const players = [];
			for (let i = 0; i < slides.length; i += 1) {
				console.log(')))))))', slides[i]);
				const element = slides[i].getElementsByClassName('yt-player')[0];
				const cover = slides[i].getElementsByClassName('yt-cover')[0];
				const id = element.getAttribute('data-id');
				console.log('id --->', id);

				const placeholder = element.hasAttribute('data-thumbnail')
					? element.getAttribute('data-thumbnail')
					: '';
				console.log('yt-cover', cover);
				if (placeholder.length) cover.innerHTML = createCustomThumbail(placeholder);
				else {
					const parent = slides[i].getElementsByClassName('video-container')[0];
					const player = new YT.Player(element, {
						height: '315',
						width: '560',
						videoId: id,
						playerVars: {
							autoplay: false,
							color: '#7fbc03',
							modestbranding: true,
							rel: 0,
						},
					});
					parent.classList.remove('pristine');
					players.push(player);
				}

				slides[i].addEventListener('click', () => {
					console.log('----->  this', this);
					// parent.remove('pristine');
					const parent2 = slides[i].getElementsByClassName('video-container')[0];
					const element2 = slides[i].getElementsByClassName('yt-player')[0];

					parent2.classList.remove('pristine');

					const player = new YT.Player(element2, {
						height: '315',
						width: '560',
						videoId: id,
						playerVars: {
							autoplay: true,
							color: '#7fbc03',
							modestbranding: true,
							rel: 0,
						},
					});
					players.push(player);
				});
			}
		};

		return true;
	}
}
