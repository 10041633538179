import Tabby from 'tabbyjs';

export default class FormBlock {
	constructor(element) {
		this.$form = document.querySelector(element);
	}

	init() {
		if (null === this.$form) {
			return false;
		}
		const tabs = new Tabby('[data-tabs]');

		return tabs;
	}
}
