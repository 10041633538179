/* eslint-disable no-unused-vars */

export default class FormBlock {
	constructor(element) {
		this.$tweetTimeline = document.querySelector(element);
	}

	init() {
		if (null === this.$tweetTimeline) {
			return false;
		}

		const observer = new MutationObserver(mutation => {
			const iframe = document.getElementById('twitter-widget-0');
			const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
			const tweetsMedias = innerDoc.querySelectorAll('.timeline-Tweet-media');
			if (null !== tweetsMedias) {
				innerDoc.querySelector('.timeline-Header').style.display = 'none';
				innerDoc.querySelector('.timeline-Footer').style.display = 'none';
				innerDoc.querySelector('.timeline-LoadMore').style.display = 'none';

				for (let x = 0; x < tweetsMedias.length; x += 1) {
					tweetsMedias[x].style.display = 'none';
				}
				const texts = innerDoc.querySelectorAll('.timeline-Tweet-text');
				for (let x = 0; x < texts.length; x += 1) {
					texts[x].style.fontSize = '14px';
					texts[x].style.lineHeight = '20px';
					texts[x].style.color = '#706F6F';
				}
			}
			// innerDoc.querySelector('.timeline-Tweet-text').style.fontSize = '14px';
		});

		const observerConfig = {
			attributes: true,
			childList: true,
			attributeOldValue: true,
			characterData: true,
		};

		const twitterFeedBox = document.getElementById('block-boxes-twitter-feed-box');

		observer.observe(twitterFeedBox, observerConfig);

		return true;
	}
}
