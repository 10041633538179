/**
 * Carousel
 *
 * @param obj element DOM object.
 */

import 'bootstrap';
import $ from 'jquery';

export default class Carousel {
	constructor(element) {
		this.rootElement = element;
	}

	init() {
		if (null === this.rootElement) {
			return false;
		}

		const burger = document.getElementById('burger');
		const menu = document.getElementById('menu');
		const submenu = document.querySelector('.menu-item-has-children .Menu-main__link');

		burger.addEventListener('click', () => {
			burger.classList.toggle('expanded');
			menu.classList.toggle('expanded');
			document.body.classList.toggle('noscroll');
		});

		// Search
		const searchButton = document.getElementById('search-button');
		const searchInput = document.getElementById('searchform');

		// variable where currentAnchor is stored

		searchButton.addEventListener('click', () => {
			searchInput.classList.toggle('expanded');
			// menu.classList.toggle('expanded');
			// document.body.classList.toggle('noscroll');
		});
		console.log('submenu', submenu);
		// if ('undefined' === typeof window.orientation) {
		// 	submenu.addEventListener('click', e => {
		// 		console.log('e', e);
		// 		e.preventDefault();
		// 		submenu.parentElement.classList.toggle('show');
		// 	});
		// }

		$('ul.Menu-main__children')
			.parent()
			.addClass('dropdown');
		$('ul.Menu-main__children').addClass('dropdown-menu');
		$('.menu-item-has-children > a').addClass('dropdown-toggle');
		$('ul.Menu-main__children li a').removeClass('dropdown-toggle');
		$('.navbar .dropdown-toggle').append('');
		$('a.dropdown-toggle').attr('data-toggle', 'dropdown');

		return true;
	}
}
