import Swiper, { Navigation, Pagination } from 'swiper';

/**
 * Carousel
 *
 * @param obj element DOM object.
 */
export default class Carousel {
	constructor(element) {
		this.rootElement = element;
	}

	init() {
		if (null === this.rootElement) {
			return false;
		}
		Swiper.use([Navigation, Pagination]);
		console.log('slider init', this.rootElement);
		const swiper = new Swiper('.js-carousel', {
			slidesPerView: 1,
			spaceBetween: 20,
			loopFillGroupWithBlank: false,
			pagination: false,
			navigation: {
				nextEl: '.News-carousel-block__slider__button--next',
				prevEl: '.News-carousel-block__slider__button--prev',
			},
			breakpoints: {
				640: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 40,
				},
			},
		});

		return swiper;
	}
}
