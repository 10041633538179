export default class FormBlock {
	constructor(element) {
		this.$form = document.querySelectorAll(element);
	}

	init() {
		if (null === this.$form) {
			return false;
		}

		const setActive = (el, active, type) => {
			console.log('---->form');
			let formField = el.parentNode;
			if ('password' === type || document.querySelector('.wpcf7-form-control-wrap')) {
				formField = el.parentNode.parentNode;
			}

			if (active) {
				formField.classList.add('is-active');
			} else {
				formField.classList.remove('is-active');
				if ('' === el.value) {
					formField.classList.remove('is-filled');
				} else {
					formField.classList.add('is-filled');
				}
			}
		};

		[].forEach.call(this.$form, el => {
			if (0 !== el.value.length) {
				setActive(el, true);
			}
			el.onblur = () => {
				setActive(el, false);
			};
			el.onfocus = () => {
				setActive(el, true);
			};
		});

		[].forEach.call(
			document.querySelectorAll('.swpm-item-password div:not(:last-child) input'),
			el => {
				el.onblur = () => {
					setActive(el, false, 'password');
				};
				el.onfocus = () => {
					setActive(el, true, 'password');
				};
			},
		);

		return true;
	}
}
