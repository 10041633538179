import Accordion from 'accordion-js';

export default class FormBlock {
	constructor(element) {
		this.$form = document.querySelector(element);
	}

	init() {
		if (null === this.$form) {
			return false;
		}
		const findAccordions = Array.from(document.querySelectorAll('.accordion-container'));
		const accordions = new Accordion(findAccordions, { duration: 200 });

		// const accordion = new Accordion('accordion-container');
		// accordion.detachEvents({ duration: 200 });

		return accordions;
	}
}
